import React, { useCallback } from 'react';
import { FormControl, FormControlLabel, Grid, Switch, TextField } from '@mui/material';
import { Organisation, ValidationErrors, validationErrors, OrganisationReducerAction } from '../..';
import { Types } from '@blumtechgroup/blum-ui-utils';

interface Props {
  organisation: Organisation;
  errors: ValidationErrors;
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>;
  dispatch: React.Dispatch<{ type: OrganisationReducerAction; value: any }>;
}

const StepDetails = ({ organisation, errors, setErrors, dispatch }: Props): React.ReactElement => {
  const validate = useCallback(
    (field: string) => {
      const errorItems: Types.UnknownObject = {};
      if (field === 'name') {
        errorItems.name = !organisation.name ? validationErrors.name : false;
      }
      setErrors({ ...errors, ...errorItems });
    },
    [organisation, errors, setErrors]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <TextField
          data-qa="name-textfield"
          required
          label="Name"
          variant="outlined"
          sx={{ width: '100%' }}
          error={errors.name as boolean}
          helperText={errors.name}
          value={organisation.name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: OrganisationReducerAction.NAME, value: event.target.value })}
          onKeyUp={() => validate('name')}
          onBlur={() => validate('name')}
        />
      </Grid>
      <Grid item xs={8}>
        <FormControl>
          <FormControlLabel
            label="Active"
            control={
              <Switch
                checked={organisation.active}
                color="primary"
                data-qa="active-toggle"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  dispatch({
                    type: OrganisationReducerAction.ACTIVE,
                    value: event.target.checked,
                  })
                }
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default StepDetails;
