import React, { useState, useReducer, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DashboardLayout, CustomBackdrop } from '@blumtechgroup/blum-react-core-components';
import { organisationReducer } from '../Common';
import Steps from '../Common/components/Steps';
import { insertOrganisationMutation, InsertOrganisationResponse } from './mutations';
import { createOrganisationInitQuery, LicenseType } from './queries';
import { useMutation, useQuery } from '@apollo/client';

const OrganisationCreate = (): React.ReactElement => {
  const navigate = useNavigate();
  const routeState = useLocation();
  const [organisation, dispatchOrganisation] = useReducer(organisationReducer, {
    name: routeState?.state?.organisationName ?? '',
    active: true,
    license_type_assignments: [],
  });

  const [licenseTypes, setLicenseTypes] = useState<LicenseType[]>([]);

  const { data: createOrganisationInitQueryData } = useQuery(createOrganisationInitQuery(), { fetchPolicy: 'no-cache' });
  const [createOrganisation, { loading }] = useMutation<InsertOrganisationResponse>(insertOrganisationMutation);

  useEffect(() => {
    if (createOrganisationInitQueryData) {
      const { license_types } = createOrganisationInitQueryData;
      setLicenseTypes(license_types);
    }
  }, [createOrganisationInitQueryData]);

  const handleSave = async () => {
    const { license_type_assignments, ...obj } = organisation;

    const createVariables = {
      object: {
        ...obj,
        license_type_assignments: {
          data: license_type_assignments,
        },
      },
    };

    const { data } = await createOrganisation({ variables: createVariables });
    if (data) {
      handleFinish(data.insert_organisations_one.id);
    }
  };

  const handleFinish = (id: string) => {
    navigate(`/admin/organisations/${id}`, { state: routeState?.state });
  };

  return (
    <>
      <DashboardLayout breadcrumbs={[{ label: 'Admin', link: '/admin/home' }, { label: 'Organisations', link: '/admin/organisations' }, { label: 'Create' }]}>
        <Steps completeLabel="Create" organisation={organisation} licenseTypes={licenseTypes} dispatch={dispatchOrganisation} handleSave={handleSave} />
      </DashboardLayout>
      {loading && <CustomBackdrop label="Creating Organisation" />}
    </>
  );
};

export default OrganisationCreate;
