import { types, query } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Types } from '@blumtechgroup/blum-ui-utils';

const _selectOrganisationsInitQuery = () => ({
  organisations: [
    {
      id: types.string,
      name: types.string,
      active: types.boolean,
    },
  ],
});

type X = Types.Unpacked<typeof _selectOrganisationsInitQuery>;
export type Organisation = X['organisations'][0];

export const selectOrganisationsInitQuery = () =>
  gql`
    ${query(_selectOrganisationsInitQuery())}
  `;
